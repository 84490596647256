<template>
    <div class="pageing">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="count"
            background>
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: ['count', 'initData'],
    data () {
        return {
            currentPage: 1,
            max: 10,
            page: 1,
        };
    },
    methods: {
        handleSizeChange (val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange (val) {
            // console.log(`当前页: ${val}`);
            this.page = val;
            this.$emit('changePage', this.page)
            this.initData();
        }
    },
}
</script>>

<style lang="scss" scoped>
.pageing {
    width: 100%;
    margin-top: 20px;
    // 页数边框及背景色
    ::v-deep.el-pager li {
        width: 32px;
        height: 32px;
        line-height: 30px;
        margin: 0 6px;
        border: 1px solid #999999;
        border-radius: 4px;
        color: #000000;
        font-size: 18px;
        font-weight: 600;
        background: #ffffff;
    }
    ::v-deep.el-input__icon {
        display: none;
    }
    ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled) {
            background-color: #fff; // 进行修改未选中背景和字体
            // color: #fff;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #ffffff; // 进行修改选中项背景和字体
            color: #000000;
            border: 2px solid #2479dd;
        }
    }
    // 左右按钮跳转
    ::v-deep.el-pagination button {
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        text-align: center;
        padding: 0;
        border: 1px solid #999999;
        ::v-deep.el-icon {
            font-size: 18px;
            color: #333333;
        }
    }
    // ::v-deep.el-pagination__total {
    //     display: none;
    // }
    ::v-deep.el-pagination .el-select .el-input .el-input__inner {
        width: 86px;
        height: 32px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
    }
}
</style>